var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer4-footer7 thq-section-padding"},[_c('div',{staticClass:"footer4-max-width thq-section-max-width"},[_c('div',{staticClass:"footer4-content"},[_c('div',{staticClass:"footer4-logo1"},[_c('img',{staticClass:"footer4-logo2",attrs:{"alt":_vm.logoAlt,"src":_vm.logoSrc}})]),_c('div',{staticClass:"footer4-links"},[_c('a',{staticClass:"thq-body-small",attrs:{"href":"#","target":"_blank","rel":"noreferrer noopener"}},[_vm._t("link1",function(){return [_vm._m(0)]})],2),_c('a',{staticClass:"thq-body-small",attrs:{"href":"#","target":"_blank","rel":"noreferrer noopener"}},[_vm._t("link2",function(){return [_vm._m(1)]})],2),_c('a',{staticClass:"thq-body-small",attrs:{"href":"#","target":"_blank","rel":"noreferrer noopener"}},[_vm._t("link3",function(){return [_vm._m(2)]})],2),_c('a',{staticClass:"thq-body-small",attrs:{"href":"#","target":"_blank","rel":"noreferrer noopener"}},[_vm._t("link4",function(){return [_vm._m(3)]})],2),_c('a',{staticClass:"thq-body-small",attrs:{"href":"#","target":"_blank","rel":"noreferrer noopener"}},[_vm._t("link5",function(){return [_vm._m(4)]})],2)])]),_c('div',{staticClass:"footer4-credits"},[_c('div',{staticClass:"thq-divider-horizontal"}),_c('div',{staticClass:"footer4-row"},[_vm._m(5),_c('div',{staticClass:"footer4-footer-links"},[_c('span',{staticClass:"footer4-text11 thq-body-small"},[_vm._t("privacyLink",function(){return [_vm._m(6)]})],2),_c('span',{staticClass:"thq-body-small"},[_vm._t("termsLink",function(){return [_vm._m(7)]})],2),_c('span',{staticClass:"thq-body-small"},[_vm._t("cookiesLink",function(){return [_vm._m(8)]})],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment3"},[_c('span',{staticClass:"footer4-text16 Poppins-paragraph"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment5"},[_c('span',{staticClass:"footer4-text18 Poppins-paragraph"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment2"},[_c('span',{staticClass:"footer4-text15 Poppins-paragraph"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment6"},[_c('span',{staticClass:"footer4-text19 Poppins-paragraph"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment1"},[_c('span',{staticClass:"footer4-text14 Poppins-paragraph"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-container"},[_c('span',{staticClass:"thq-body-small"},[_vm._v("© 2024 Racketnation.ph")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment8"},[_c('span',{staticClass:"footer4-text21"},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment4"},[_c('span',{staticClass:"footer4-text17"},[_vm._v("Terms of Service")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer4-fragment7"},[_c('span',{staticClass:"footer4-text20"},[_vm._v("Cookies Policy")])])
}]

export { render, staticRenderFns }