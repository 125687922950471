<template>
  <header class="navbar8-container1">
    <header data-thq="thq-navbar" class="navbar8-navbar-interactive">
      <img :alt="logoAlt" :src="logoSrc" class="navbar8-image1" />
      <div data-thq="thq-navbar-nav" class="navbar8-desktop-menu">
        <nav class="navbar8-links1">
          <a :href="link1Url" class="navbar8-link11 thq-link thq-body-small">
            <slot name="link1">
              <!--Default content for link1-->
              <div><span>HOME</span></div>
            </slot>
          </a>
          <a :href="link2Url" class="thq-link thq-body-small">
            <slot name="link2">
              <!--Default content for link2-->
              <div><span style="font-family: 'Poppins';">ABOUT US</span></div>
            </slot>
          </a>
         
          <div
            @click="link3DropdownVisible = !link3DropdownVisible"
            class="navbar8-link4-dropdown-trigger"
          >
            <span class="thq-link thq-body-small">
              <slot name="link3">
                <!--Default content for link4-->
                <div><span>EVENTS</span></div>
              </slot>
            </span>
            <div class="navbar8-icon-container1">
              <div v-if="link3DropdownVisible" class="navbar8-container2">
                <svg viewBox="0 0 1024 1024" class="navbar8-icon10">
                  <path d="M298 426h428l-214 214z"></path>
                </svg>
              </div>
              <div v-if="!link3DropdownVisible" class="navbar8-container3">
                <svg viewBox="0 0 1024 1024" class="navbar8-icon12">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
          </div>



          <div
            @click="link4DropdownVisible = !link4DropdownVisible"
            class="navbar8-link4-dropdown-trigger"
          >
            <span class="thq-link thq-body-small">
              <slot name="link4">
                <!--Default content for link4-->
                <div><span>OUR PARTNERS</span></div>
              </slot>
            </span>
            <div class="navbar8-icon-container1">
              <div v-if="link4DropdownVisible" class="navbar8-container2">
                <svg viewBox="0 0 1024 1024" class="navbar8-icon10">
                  <path d="M298 426h428l-214 214z"></path>
                </svg>
              </div>
              <div v-if="!link4DropdownVisible" class="navbar8-container3">
                <svg viewBox="0 0 1024 1024" class="navbar8-icon12">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
          </div>
        </nav>


        <div class="navbar8-buttons1">
          <button class="navbar8-action11 thq-button-animated thq-button-filled">
            <span>
              <slot name="action1">
                <!--Default content for action1-->
                <div><span>Register</span></div>
              </slot>
            </span>
          </button>
        </div>
      </div>

 <!--burger-->     
      <div data-thq="thq-burger-menu" class="navbar8-burger-menu">
        <svg viewBox="0 0 1024 1024" class="navbar8-icon14">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>

<!--mobile menu-->     
      <div data-thq="thq-mobile-menu" class="navbar8-mobile-menu">
        <div class="navbar8-nav">
          <div class="navbar8-top">
            <img :alt="logoAlt" :src="logoSrc" class="navbar8-logo" />
            <div data-thq="thq-close-menu" class="navbar8-close-menu">
              <svg viewBox="0 0 1024 1024" class="navbar8-icon16">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav class="navbar8-links2">
            <a :href="link1Url" class="navbar8-link12 thq-link thq-body-small">
              <slot name="link1">
                <!--Default content for link1-->
                <div class="navbar8-fragment14">
                  <span class="navbar8-text18">HOME</span>
                </div>
              </slot>
            </a>
            <a :href="link2Url" class="thq-link thq-body-small">
              <slot name="link2">
                <!--Default content for link2-->
                <div class="navbar8-fragment12">
                  <span class="navbar8-text16">ABOUT US</span>
                </div>
              </slot>
            </a>
<!--mobile events-->       
            <div class="navbar8-link3-accordion">
              <div
                @click="link3AccordionOpen = !link3AccordionOpen"
                class="navbar8-trigger"
              >
                <span class="thq-link thq-body-small">
                  <slot name="link3">
                    <!--Default content for link4-->
                    <div class="navbar8-fragment17">
                      <span class="navbar8-text21">EVENTS</span>
                    </div>
                  </slot>
                </span>
              </div>
              
              <div v-if="link3AccordionOpen" class="navbar8-container6">
                <a :href="linkUrlEvent1">
                  <div class="navbar8-menu-item1">
                    <img
                      :alt="events1ImageAlt"
                      :src="events1ImageSrc"
                      class="navbar8-page1-image1"
                    />
                    <div class="navbar8-content1">
                      <span class="navbar8-page11 thq-body-large">
                        <slot name="events1">
                          <!--Default content for events-->
                          <div><span>Demo Day</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events1Description">
                          <!--Default content for events-->
                          <div>
                            <span>demo day</span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
                <a :href="linkUrlEvent2">
                  <div class="navbar8-menu-item2">
                    <img
                      :alt="page2ImageAlt"
                      :src="page2ImageSrc"
                      class="navbar8-page2-image1"
                    />
                    <div class="navbar8-content2">
                      <span class="navbar8-page21 thq-body-large">
                        <slot name="events2">
                          <!--Default content for events-->
                          <div><span>Tournaments</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events2Description">
                          <!--Default content for events-->
                          <div>
                            <span>....
                            </span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
                <a :href="linkUrlEvent3">
                  <div class="navbar8-menu-item3">
                    <img
                      :alt="page3ImageAlt"
                      :src="page3ImageSrc"
                      class="navbar8-page3-image1"
                    />
                    <div class="navbar8-content3">
                      <span class="navbar8-page31 thq-body-large">
                        <slot name="events3">
                          <!--Default content for events-->
                          <div><span>Collaborations</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events3Description">
                          <!--Default content for events3Description-->
                          <div>
                            <span>
                             collab
                            </span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
              
              </div>
            </div>
<!--mobile partners-->
          <div class="navbar8-link4-accordion">
              <div
                @click="link4AccordionOpen = !link4AccordionOpen"
                class="navbar8-trigger"
              >
                <span class="thq-link thq-body-small">
                  <slot name="link4">
                    <!--Default content for link4-->
                    <div class="navbar8-fragment17">
                      <span class="navbar8-text21">OUR PARTNERS</span>
                    </div>
                  </slot>
                </span>
              </div>
              
              
              <div v-if="link4AccordionOpen" class="navbar8-container6">
                <a :href="linkUrlEvent1">
                  <div class="navbar8-menu-item1">
                    <img
                      :alt="page1ImageAlt"
                      :src="page1ImageSrc"
                      class="navbar8-page1-image1"
                    />
                    <div class="navbar8-content1">
                      <span class="navbar8-page11 thq-body-large">
                        <slot name="events1">
                          <!--Default content for events-->
                          <div><span>Brands</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events1Description">
                          <!--Default content for events-->
                          <div>
                            <span>....</span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
                <a :href="linkUrlEvent2">
                  <div class="navbar8-menu-item2">
                    <img
                      :alt="page2ImageAlt"
                      :src="page2ImageSrc"
                      class="navbar8-page2-image1"
                    />
                    <div class="navbar8-content2">
                      <span class="navbar8-page21 thq-body-large">
                        <slot name="events2">
                          <!--Default content for events-->
                          <div><span>Coaches</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events2Description">
                          <!--Default content for events-->
                          <div>
                            <span>....
                            </span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
                <a :href="linkUrlEvent3">
                  <div class="navbar8-menu-item3">
                    <img
                      :alt="page3ImageAlt"
                      :src="page3ImageSrc"
                      class="navbar8-page3-image1"
                    />
                    <div class="navbar8-content3">
                      <span class="navbar8-page31 thq-body-large">
                        <slot name="events3">
                          <!--Default content for events-->
                          <div><span>Courts</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events3Description">
                          <!--Default content for events3Description-->
                          <div>
                            <span>
                             .....
                            </span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
                <a :href="linkUrlEvent3">
                  <div class="navbar8-menu-item3">
                    <img
                      :alt="page3ImageAlt"
                      :src="page3ImageSrc"
                      class="navbar8-page3-image1"
                    />
                    <div class="navbar8-content3">
                      <span class="navbar8-page31 thq-body-large">
                        <slot name="events3">
                          <!--Default content for events-->
                          <div><span>PHILTA</span></div>
                        </slot>
                      </span>
                      <span class="thq-body-small">
                        <slot name="events3Description">
                          <!--Default content for events3Description-->
                          <div>
                            <span>
                             .....
                            </span>
                          </div>
                        </slot>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>

          </nav>
          <div class="navbar8-buttons2">
            <button class="thq-button-filled">
              <span>
                <slot name="action1">
                  <!--Default content for action1-->
                  <div class="navbar8-fragment11">
                    <span class="navbar8-text15">Register</span>
                  </div>
                </slot>
              </span>
            </button>
          </div>
        </div>
    </div>
<!--end of mobile-->


<!-- events accordion-->
<div v-if="link3DropdownVisible" class="navbar8-container7 thq-box-shadow">
        <div class="navbar8-link5-menu-list">
          <a :href="linkUrlEvents1">
            <div class="navbar8-menu-item5">
              <!-- <img
                :alt="page1ImageAlt"
                :src="page1ImageSrc"
                class="navbar8-page1-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content5">
                <span class="navbar8-page12 thq-body-large">
                  <slot name="events1">
                    <!--Default content for page1-->
                    <div class="navbar8-fragment13">
                      <span class="navbar8-text17 Poppins-paragraph" ></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="events1Description">
                    <!--Default content for page1Description-->
                    <div class="navbar8-fragment18">
                      <span class="navbar8-text22 Poppins-title">
                       
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage2">
            <div class="navbar8-menu-item6">
              <!-- <img
                :alt="page2ImageAlt"
                :src="page2ImageSrc"
                class="navbar8-page2-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content6">
                <span class="navbar8-page22 thq-body-large">
                  <slot name="events2">
                    <!--Default content for page2-->
                    <div class="navbar8-fragment16">
                      <span class="navbar8-text20"></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="events2Description">
                    <!--Default content for page2Description-->
                    <div class="navbar8-fragment19">
                      <span class="navbar8-text23">
                       
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage3">
            <div class="navbar8-menu-item7">
              <!-- <img
                :alt="page3ImageAlt"
                :src="page3ImageSrc"
                class="navbar8-page3-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content7">
                <span class="navbar8-page32 thq-body-large">
                  <slot name="events3">
                    <!--Default content for page3-->
                    <div class="navbar8-fragment21">
                      <span class="navbar8-text25"></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="events3Description">
                    <!--Default content for page3Description-->
                    <div class="navbar8-fragment22">
                      <span class="navbar8-text26">
                       </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage3">
            <div class="navbar8-menu-item7">
              <!-- <img
                :alt="page4ImageAlt"
                :src="page4ImageSrc"
                class="navbar8-page3-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content7">
                <span class="navbar8-page32 thq-body-large">
                  <slot name="events4">
                    <!--Default content for page3-->
                    <div class="navbar8-fragment21">
                      <span class="navbar8-text25"></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="event43Description">
                    <!--Default content for page3Description-->
                    <div class="navbar8-fragment22">
                      <span class="navbar8-text26">
                       </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
<!-- end of events accordion-->

<!--accordion partners-->
      <div v-if="link4DropdownVisible" class="navbar8-container7 thq-box-shadow">
        <div class="navbar8-link5-menu-list">
          <a :href="linkUrlPage1">
            <div class="navbar8-menu-item5">
              <!-- <img
                :alt="page1ImageAlt"
                :src="page1ImageSrc"
                class="navbar8-page1-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content5">
                <span class="navbar8-page12 thq-body-large">
                  <slot name="page1">
                    <!--Default content for page1-->
                    <div class="navbar8-fragment13">
                      <span class="navbar8-text17 Poppins-paragraph" ></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page1Description">
                    <!--Default content for page1Description-->
                    <div class="navbar8-fragment18">
                      <span class="navbar8-text22 Poppins-title">
                        Welcome to Racketnation.ph
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage2">
            <div class="navbar8-menu-item6">
              <!-- <img
                :alt="page2ImageAlt"
                :src="page2ImageSrc"
                class="navbar8-page2-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content6">
                <span class="navbar8-page22 thq-body-large">
                  <slot name="page2">
                    <!--Default content for page2-->
                    <div class="navbar8-fragment16">
                      <span class="navbar8-text20"></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page2Description">
                    <!--Default content for page2Description-->
                    <div class="navbar8-fragment19">
                      <span class="navbar8-text23">
                        We are....
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage3">
            <div class="navbar8-menu-item7">
              <!-- <img
                :alt="page3ImageAlt"
                :src="page3ImageSrc"
                class="navbar8-page3-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content7">
                <span class="navbar8-page32 thq-body-large">
                  <slot name="page3">
                    <!--Default content for page3-->
                    <div class="navbar8-fragment21">
                      <span class="navbar8-text25"></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page3Description">
                    <!--Default content for page3Description-->
                    <div class="navbar8-fragment22">
                      <span class="navbar8-text26">
                        Meet our coaches</span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage4">
            <div class="navbar8-menu-item8">
              <!-- <img
                :alt="page4ImageAlt"
                :src="page4ImageSrc"
                class="navbar8-page4-image2 thq-img-ratio-1-1"
              /> -->
              <div class="navbar8-content8">
                <span class="navbar8-page42 thq-body-large">
                  <slot name="page4">
                    <!--Default content for page4-->
                    <div class="navbar8-fragment15">
                      <span class="navbar8-text19"></span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page4Description">
                    <!--Default content for page4Description-->
                    <div class="navbar8-fragment10">
                      <span class="navbar8-text14">
                        Be the first to know</span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
<!--end of partners accordion-->

   
    </header>
    <div
      @click="link3DropdownVisible = false"
      v-if="link3DropdownVisible"
      class="navbar8-container8"
    ></div>
    <div
      @click="link4DropdownVisible = false"
      v-if="link4DropdownVisible"
      class="navbar8-container8"
    ></div>
  </header>
</template>

<script>
export default {
  name: 'Navbar8',
  props: {
    logoAlt: {
      type: String,
      default: 'Company Logo',
    },
    logoSrc: {
      type: String,
      default:
        '/logo.png',
    },
    link1Url: {
      type: String,
      default: '#',
    },
    linkUrlEvent1: {
      type: String,
      default: '#',
    },
    linkUrlPage1: {
      type: String,
      default: '#',
    },
   
    page1ImageAlt: {
      type: String,
      default: '',
    },
    page1ImageSrc: {
      type: String,
      default: '',
    },
    events1ImageAlt:{
      type: String,
      default: 'demo',
    },
    events1ImageSrc:{
      type: String,
      default: '',
    },
    link2Url: {
      type: String,
      default: '#',
    },
    page2ImageSrc: {
      type: String,
      default:
        '',
    },
    page2ImageAlt: {
      type: String,
      default: '',
    },
    linkUrlPage2: {
      type: String,
      default: '#',
    },
    page3ImageSrc: {
      type: String,
      default:
        '',
    },
    
    link3Url: {
      type: String,
      default: '#',
    },
    linkUrlPage3: {
      type: String,
      default: '#',
    },
    page3ImageAlt: {
      type: String,
      default: '',
    },
    page4ImageSrc: {
      type: String,
      default:
        '',
    },
    linkUrlPage4: {
      type: String,
      default: '#',
    },
   
    page4ImageAlt: {
      type: String,
      default: '',
    },
    
  },
  data() {
    return {
      link3AccordionOpen: false,
      link3DropdownVisible: false,
      link4AccordionOpen: false,
      link4DropdownVisible: false,
      
      
    }
  },
}
</script>

<style scoped>
.navbar8-container1 {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: sticky;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar8-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.navbar8-image1 {
  height: 3rem;
}
.navbar8-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar8-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar8-link11 {
  text-decoration: none;
}
.navbar8-link31 {
  text-decoration: none;
}
.navbar8-link4-dropdown-trigger {
  gap: 4px;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-container2 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownLeft;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-icon10 {
  width: 24px;
  height: 24px;
}
.navbar8-container3 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownRight;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-icon12 {
  width: 24px;
  height: 24px;
}
.navbar8-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar8-action11 {
  display: flex;
  flex-direction: row;
}
.navbar8-action21 {
  display: flex;
  flex-direction: row;
}
.navbar8-burger-menu {
  display: none;
}
.navbar8-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar8-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar8-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar8-logo {
  height: 3rem;
}
.navbar8-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar8-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar8-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
.navbar8-link12 {
  text-decoration: none;
}
.navbar8-link32 {
  text-decoration: none;
}
.navbar8-link4-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.navbar8-icon-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon18 {
  width: 24px;
  height: 24px;
}
.navbar8-container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon20 {
  width: 24px;
  height: 24px;
}
.navbar8-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}
.navbar8-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page1-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page11 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page2-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page21 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page3-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page31 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page4-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content4 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page41 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navbar8-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar8-container7 {
  top: 100%;
  left: 0px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  animation-name: fadeInDownBig;
  animation-delay: 0s;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-link5-menu-list {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-oneandhalfunits);
  grid-gap: var(--dl-space-space-halfunit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.navbar8-menu-item5 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page1-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content5 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page12 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item6 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page2-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content6 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page22 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item7 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page3-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content7 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page32 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item8 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page4-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content8 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page42 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-container8 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-fragment10 {
  display: contents;
}
.navbar8-text14 {
  display: inline-block;
}
.navbar8-fragment11 {
  display: contents;
}
.navbar8-text15 {
  display: inline-block;
}
.navbar8-fragment12 {
  display: contents;
}
.navbar8-text16 {
  display: inline-block;
}
.navbar8-fragment13 {
  display: contents;
}
.navbar8-text17 {
  display: inline-block;
}
.navbar8-fragment14 {
  display: contents;
}
.navbar8-text18 {
  display: inline-block;
}
.navbar8-fragment15 {
  display: contents;
}
.navbar8-text19 {
  display: inline-block;
}
.navbar8-fragment16 {
  display: contents;
}
.navbar8-text20 {
  display: inline-block;
}
.navbar8-fragment17 {
  display: contents;
}
.navbar8-text21 {
  display: inline-block;
}
.navbar8-fragment18 {
  display: contents;
}
.navbar8-text22 {
  display: inline-block;
}
.navbar8-fragment19 {
  display: contents;
}
.navbar8-text23 {
  display: inline-block;
}
.navbar8-fragment20 {
  display: contents;
}
.navbar8-text24 {
  display: inline-block;
}
.navbar8-fragment21 {
  display: contents;
}
.navbar8-text25 {
  display: inline-block;
}
.navbar8-fragment22 {
  display: contents;
}
.navbar8-text26 {
  display: inline-block;
}
.navbar8-fragment23 {
  display: contents;
}
.navbar8-text27 {
  display: inline-block;
}
@media(max-width: 767px) {
  .navbar8-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar8-desktop-menu {
    display: none;
  }
  .navbar8-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar8-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .navbar8-container6 {
    grid-gap: 0;
  }
  .navbar8-link5-menu-list {
    display: none;
  }
}
@media(max-width: 479px) {
  .navbar8-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar8-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
</style>
